<div>
  @if(enableDelivery){
  <span class="">Select delivery option:</span>
  }
</div>
  <div class="flex justify-between ">
    <div class="flex items-center space-x-1">
      <input type="radio" [(ngModel)]="selectedDeliveryMode" (change)="deliveryModeChanged()" [value]=0
        class="w-4 h-4 rounded accent-vendblocblue" [attr.name]="'delivery_' + uniqueId" id="pickup" />
      <label for="pickup">Pick Up</label>
    </div>
  @if(enableDelivery){
  <div class="flex items-center space-x-1">
    <input type="radio" [(ngModel)]="selectedDeliveryMode" (change)="deliveryModeChanged()" [value]=1
      class="w-4 h-4 rounded accent-vendblocblue" [attr.name]="'delivery_' + uniqueId" id="deliver" />
    <label for="deliver">Delivery</label>
  </div>}

</div>