import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { CreateOutlet, Currency, Outlet, ResponseDTO, User } from '@models/index';
import { AuthService, CurrencyService, OutletService } from '@data-access/services';
import { TableComponent } from "../table/table.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';

@Component({
    selector: 'app-my-outlets',
    standalone: true,
    templateUrl: './my-outlets.component.html',
    styleUrl: './my-outlets.component.css',
    imports: [TableModule, DashboardComponent, ReactiveFormsModule, DropdownModule, ButtonModule, DialogModule, TableComponent]
})
export class MyOutletsComponent {
  loading: boolean = false;
  visible: boolean = false;
  currencies: Currency[] = [];
  user: User | null = null;
  outlets: Outlet[] = [];
  enableAdd:boolean = false;
  showDialog() {
    this.visible = true;
  }

  tableCols: PrimeTableColumn[] = [
    { field: 'name', header: 'Name' },
    { field: 'store', header: 'Store' },
    { field: 'id', header: '' }
  ];

  constructor(private currencyService: CurrencyService,
    private outletService: OutletService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.authService.user$.pipe(first()).subscribe((response) => {
      this.user = response;
      if(this.user?.role === "uncleshenor"){
        this.enableAdd = true;
      }
    });

    this.currencyService.getCurrencies().pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.currencies = result.data as Currency[];
        }
      },
      error: (e) => {
        console.log(e);
      }
    });


    this.outletService.getOutletsByUser(this.user?.id as string).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.outlets = result.data as Outlet[];
        }
      },
      error: (e) => {
        this.toastr.error("Oops, pls try again, something went wrong");
        console.log(e);
      }
    });
  }

  createOutletForm = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.minLength(3)]),
    currency: new FormControl(),
    storeId: new FormControl(),
  });

  navigateToOutlet(id: number) {
    const outletRoute = 'outlet/' + id;
    this.router.navigate([outletRoute]);
  }

  createOutlet() {
    if(!this.createOutletForm.valid)return;
    const formValue = this.createOutletForm.value;
    const data: CreateOutlet = {
      name: formValue.name as string,
      currencyId: formValue.currency.id,
      storeId: formValue.storeId
    };
    this.outletService.createOutlet(data).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.visible = false;
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    })
  }
}
