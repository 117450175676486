import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TableComponent } from '@components/table/table.component';
import { InventoryService, OutletService } from '@data-access/services';
import { CreateInventory, Inventory, Outlet, Product, ResponseDTO } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Subject, first, takeUntil } from 'rxjs';
import { OutletComponent } from "../outlet/outlet.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';

@Component({
  selector: 'app-outlet-inventory',
  standalone: true,
  templateUrl: './outlet-inventory.component.html',
  styleUrl: './outlet-inventory.component.css',
  imports: [OutletComponent, TableComponent, InputTextModule, DropdownModule, ReactiveFormsModule, DialogModule,
    InputNumberModule, FormsModule
  ]
})
export class OutletInventoryComponent implements OnDestroy {

  inventories: Inventory[] = [];
  currentInventory : Inventory | null = null; 
  outlet: Outlet | undefined = undefined;
  addDialog: boolean = false;
  editDialog: boolean = false;
  products: Product[] = [];
  dialogVisible: boolean = false;
  private $destroy = new Subject<void>();

  constructor(private inventoryService: InventoryService, private outletService: OutletService,
    private toastr: ToastrService
  ) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      this.outlet = result as Outlet;
    });
    this.fetchInventory(this.outlet?.id ?? 0);
    this.fetchProducts(this.outlet?.id ?? 0);
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  updateCurrentInventory(inventoryid:number){
    const item = this.inventories.find(i => i.id === inventoryid);
    if(item){
      item.active = !this.currentInventory?.active;
      this.currentInventory = item;
    }
  }
  
  tableCols: PrimeTableColumn[] = [
    { field: 'product', header: 'Product' },
    { field: 'productCategory', header: 'Category' },
    { field: 'unit', header: 'Unit' },
    { field: 'price', header: 'Price' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'active', header: 'Active' }];

    createInventoryForm = new FormGroup({
      name: new FormControl(),
      product: new FormControl(),
      costPrice: new FormControl(),
      salesPrice: new FormControl(),
      quantity: new FormControl(),
      manufacturingDate: new FormControl(),
      expiringDate: new FormControl(),
      remark: new FormControl(),
    });
  
    editInventoryForm = new FormGroup({
      name: new FormControl(),
      product: new FormControl(),
      productId:new FormControl(),
      costPrice: new FormControl(),
      salesPrice: new FormControl(),
      quantity: new FormControl(),
      manufacturingDate: new FormControl(),
      expiringDate: new FormControl(),
      remark: new FormControl(),
    });


  showAddDialog() {
    this.addDialog = !this.addDialog;
  }

  updateInventory(inventory: Inventory) {
    const isInventoryInList = this.inventories.findIndex(x => x.productId == inventory.productId);
    if (isInventoryInList < 0) {
      this.inventories.push(inventory);
    }
    else {
      this.inventories[isInventoryInList].quantity = inventory.quantity;
      this.inventories[isInventoryInList].price = inventory.price;
      this.inventories[isInventoryInList].salesPrice = inventory.salesPrice;
    }
  }

  showEditDialog(index:number) {
    this.currentInventory = this.inventories[index];
    this.editInventoryForm.patchValue({product:this.currentInventory.product, costPrice:this.currentInventory.price, salesPrice:this.currentInventory.salesPrice, quantity:this.currentInventory.quantity, productId:this.currentInventory?.productId});
    this.editDialog = !this.editDialog;
  }

  deleteInventory(eventAndId: { event: Event, id: number; }) {
    console.log(eventAndId);
  }
  
  toggleAvailable() {
    const inventory = this.currentInventory?.id as number;
    this.inventoryService.enableDisableInventory(inventory).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.toastr.success(result.message);
          this.updateCurrentInventory(inventory);
        }
        else {
          this.toastr.error(result.message);
        }
      },
      error: (err) => {
        console.log("Something went wrong");
        console.log(err);
      }
    });
  }    

  addInventory() {
    const formData = this.createInventoryForm.value;
    const data: CreateInventory = {
      productId: formData.product.id,
      remark: formData.remark,
      salesPrice: formData.salesPrice,
      costPrice: formData.costPrice,
      manufacturingDate: formData.manufacturingDate,
      expiringDate: formData.expiringDate,
      quantity: formData.quantity,
      outletId: this.outlet?.id ?? 0
    };
    this.inventoryService.createInventory(data).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const newInventory = result.data as Inventory;
          this.updateInventory(newInventory);
          this.createInventoryForm.reset();
          this.toastr.success(result.message);
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (err) => {
        console.log("Something went wrong");
        console.log(err);
      }
    });
    this.showAddDialog();
  }

  editInventory() {
    const formData = this.editInventoryForm.value;
    const data: CreateInventory = {
      productId: formData.productId,
      remark: formData.remark,
      salesPrice: formData.salesPrice,
      costPrice: formData.costPrice,
      manufacturingDate: formData.manufacturingDate,
      expiringDate: formData.expiringDate,
      quantity: formData.quantity,
      outletId: this.outlet?.id ?? 0
    };
    this.inventoryService.editInventory(data).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const newInventory = result.data as Inventory;
          this.updateInventory(newInventory);
          this.toastr.success(result.message);
        }
        else {
          this.toastr.error(result.message);
        }
      },
      error: (err) => {
        this.toastr.error("Something went wrong, we are working on it");
      }
    });
  }

  public fetchInventory(outlet: number) {
    if(outlet === 0)return;
    this.inventoryService.getInventoryByOutlet(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          console.log(result.data);
          this.inventories = result.data as Inventory[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (err) => {
        console.log("Something went wrong");
        console.log(err);
      }
    });
  }

  public fetchProducts(outlet: number) {
    if(outlet === 0)return;
    this.outletService.getOutletProducts(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.products = result.data as Product[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (err) => {
        console.log("Something went wrong");
        console.log(err);
      }
    });
  }

}
