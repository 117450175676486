@if(displayModal){
<div 
id="dialog"
class="fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen z-50"
>
<!-- Close Button -->
<div

  class="group absolute top-16 right-2 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full md:bg-white md:top-4 hover:cursor-pointer hover:-translate-y-0.5 transition duration-150"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-6 h-6 text-black group-hover:text-gray-600"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
</div>
<div
  class="bg-white rounded-xl shadow-md p-4 mx-auto my-20 w-[90%] max-h-[80%] border"
>
  <div class="flex items-center gap-5">
    <div
      class="m-0 md:flex-col shadow-lg w-full md:p-1 lg:p-3 xl:p-4 bg-[#0f172a08] space-y-6"
    >
    
    </div>
  </div>
</div>
</div>
}