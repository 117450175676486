import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthLayoutComponent, HeaderComponent } from '@components/layouts';
import { AuthService, LoadingService } from '@data-access/services';
import { LoginDTO, LoginResponseDTO, ResponseDTO } from '@models/index';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { PasswordFieldComponent } from "../../shared/password-field/password-field.component";
import { TextInputFieldComponent } from '@components/shared/text-input-field/text-input-field.component';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterOutlet,
    RouterLink,
    HeaderComponent,
    ReactiveFormsModule,
    AuthLayoutComponent, PasswordFieldComponent,TextInputFieldComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  ngOnInit(): void {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: environment.googleOauthKey,
        callback: this.googleLogin.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("googleSignInDiv"), { theme: "outline", size: "large", width: "100%", logo_alignment: "center" }
      );
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    }
  }

  loginFormSubmitted : boolean = false;

  constructor(private authService: AuthService, private toastr: ToastrService,
    private router: Router,
    private loadingService: LoadingService, private zone: NgZone) { }

  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
  });

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async googleLogin(response: CredentialResponse) {
    this.authService.loginGoogleUser(response).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.authService.saveUserDetails(result.data as LoginResponseDTO);
        }
      },
      complete: () => {
        const isAuthenticated: boolean = this.authService.isUserAuthenticated();
        if (isAuthenticated) {
          this.zone.run(() => {
            this.loadingService.isLoading.next(false);
            this.toastr.success("Login Successful", "Successful Operation");
            this.router.navigate(['/']);
          });

        } else {
          this.zone.run(() => {
            this.toastr.error("Unable to sign you in", "Something went wrong");
          });
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", "Something went wrong");
        this.loadingService.isLoading.next(false);
      }
    })
  }

login() {
    this.loginFormSubmitted = true;
    if (!this.loginForm.valid){
      this.markFormGroupTouched(this.loginForm);
      return;
    } 
    this.authService.logOut();
    this.loadingService.isLoading.next(true);
    const formValue = this.loginForm.value;
    const loginData: LoginDTO = {
      email: formValue.email as string,
      password: formValue.password as string
    };
    this.authService.loginUser(loginData).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.authService.saveUserDetails(result.data as LoginResponseDTO);
        }
        else {
          this.toastr.error(result.message);
        }
        this.loadingService.isLoading.next(false);
      },
      complete: () => {
        const isAuthenticated: boolean = this.authService.isUserAuthenticated();
        if (isAuthenticated) {
          this.loadingService.isLoading.next(false);
          this.toastr.success("Login Successful", "Successful Operation");
          this.router.navigate(['/']);
        } else {
          this.toastr.error("Unable to sign you in", "Something went wrong");
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", "Something went wrong");
        this.loadingService.isLoading.next(false);
      }
    });
  }

}
