<app-outlet>
    <app-table [cols]="tableCols" [enableAdd]="true" (addButtonPressed)="showDialog()" (removeButtonPressed)="deleteProduct($event)" [tableData]="products" tableName="Products"></app-table>

    <form [formGroup]="createProductForm" (submit)="saveProduct()">
        <p-dialog [(visible)]="productDialog" [style]="{ width: '450px' }" header="Add Product" [modal]="true"
            styleClass="p-fluid">

            <ng-template pTemplate="content">

                <!-- <div class="flex flex-col w-full space-y-2"> -->
                <div class="form_div">
                    <label for="">Product's Name</label>
                    <input class="p-3 border rounded-md" placeholder="Name" formControlName="name" type="text"
                        required />
                </div>
                <div class="form_div">
                    <label for="">Category</label>
                    <p-dropdown class="border" [options]="productCategories" formControlName="productCategory"
                        placeholder="Select a Category" optionLabel="name" inputId="float-label"></p-dropdown>
                </div>
                <div class="form_div">
                    <label for="">Type</label>
                    <p-dropdown class="border" [options]="productTypes" formControlName="productType"
                        placeholder="Select a Type" optionLabel="name" inputId="float-label"></p-dropdown>
                </div>
                <div class="form_div">
                    <label for="">Unit of Measurement</label>
                    <p-dropdown class="border"  [options]="units" formControlName="unit" placeholder="Select a Unit"
                        optionLabel="Unit of Measurement" inputId="float-label"></p-dropdown>
                </div>
                <!-- </div> -->

            </ng-template>

            <ng-template pTemplate="footer">
                <div class="space-x-5">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                    (click)="showDialog()"></button>
                <button pButton type="submit" pRipple label="Save" icon="pi pi-check" class="p-button-text"></button>
                </div>
            </ng-template>

        </p-dialog>
    </form>

</app-outlet>