import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { OutletService } from '@data-access/services';
import { ToastrService } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OutletComponent } from '../outlet/outlet.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { first } from 'rxjs/internal/operators/first';
import { CreateOutletDeliveryLocation, deliveryLocation, Outlet, ResponseDTO } from '@models/index';
import { Subject, takeUntil } from 'rxjs';
import { AvatarModule } from 'primeng/avatar';
import { FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { TextInputFieldComponent } from "../../shared/text-input-field/text-input-field.component";

@Component({
  selector: 'app-outlet-settings',
  standalone: true,
  templateUrl: './outlet-settings.component.html',
  styleUrl: './outlet-settings.component.css',
  imports: [OutletComponent, ReactiveFormsModule, InputNumberModule, CardModule, SelectButtonModule,
    ButtonModule, KeyFilterModule, InputTextModule, TextInputFieldComponent, CheckboxModule, FormsModule, AvatarModule, FileUploadModule, DialogModule]
})
export class OutletSettingsComponent implements OnDestroy {
manageDeliveryLocationDialog : boolean = false;
  onUpload($event: FileUploadEvent) {
    const selectedFile = $event.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    formData.append('outletId', this.outlet?.id);
    this.outletService.uploadOutletImage(formData).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (errorResponse) => {
        if (errorResponse.error && errorResponse.error.errors) {
          const errors = errorResponse.error.errors;
          const fileErrors = errors.file;
          if (fileErrors && fileErrors.length > 0) {
            // console.log(fileErrors[0]);
            this.toastr.error(fileErrors[0]); // Output: "The file field is required."
          }
        }
      }
    })
  }

  onlineDeliveryChecked: boolean = false;
  onlineOrderChecked: boolean = false;
  lessThanZeroInventoryChecked: boolean = false;
  inHouseDispatchersOnlyChecked: boolean = false;
  outletId : number = 0;
  private destroy$ = new Subject<void>();

  constructor(private outletService: OutletService, private toastr: ToastrService) {
    this.outletService.outlet$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.outlet = result as Outlet;
      this.outletId = result?.id ?? 0;
    });
    this.updateOutletForm.setValue({ name: this.outlet?.name, phone: this.outlet?.phone, address: this.outlet?.address, deliveryFee: this.outlet?.deliveryFee, onlineOrder: this.outlet?.onlineOrder, lessThanZeroInvetory: this.outlet?.allowLessThanZeroInventory, inHouseDispatchersOnly: this.outlet?.inHouseDispatchersOnly, onlineDelivery: this.outlet?.delivery });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleDeliveryLocationDialog(){
    this.manageDeliveryLocationDialog = !this.manageDeliveryLocationDialog;
  }

  createDeliveryLocationForm = new FormGroup({
    location: new FormControl(),
    dayAmount: new FormControl(0, [Validators.required]),
    nightAmount: new FormControl(0, [Validators.required]),
  })

  updateOutletForm = new FormGroup({
    name: new FormControl(),
    phone: new FormControl(),
    address: new FormControl(),
    deliveryFee: new FormControl(),
    onlineOrder: new FormControl<boolean>(false),
    onlineDelivery: new FormControl<boolean>(false),
    inHouseDispatchersOnly: new FormControl<boolean>(false),
    lessThanZeroInvetory: new FormControl()
  });

  outlet: any;
  deliveryOptions: any[] = [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }];
  value: string = 'no';

  createOutletDeliveryLocation() {
    if(!this.createDeliveryLocationForm.valid)return;
    const formValue = this.createDeliveryLocationForm.value;
    const data: CreateOutletDeliveryLocation = {
      location: formValue.location as string,
      outletId: this.outletId,
      dayAmount: formValue.dayAmount ?? 0,
      nightAmount: formValue.nightAmount ?? 0
    };
    this.outletService.createOutletDeliveryLocation(data).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          const newLocation = response.data as deliveryLocation;
          if(this.outlet.deliveryLocations){
            const data = {
               dayAmount : formValue.dayAmount ?? 0,
               nightAmount : formValue.nightAmount ?? 0,
               location : formValue.location,
               id:newLocation.id as number
            }
            this.outlet.deliveryLocations.push(data)
          }
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error(err.error.message);
      },
    })
  }

  deleteLocation(id: number) {
    this.outletService.deleteOutletDeliveryLocation(id).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          // this.products = this.products.filter(x => x.id !== id);
         if(this.outlet){
         const item =  this.outlet.deliveryLocations.findIndex((x: { id: number; }) =>x.id === id);
         this.outlet.deliveryLocations.splice(item,1);
         }
          this.toastr.success(response.message);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  updateOutlet() {
    const oldOutlet = { ...this.outlet };
    const formValue = this.updateOutletForm.value;
    if (this.outlet.deliveryFee !== formValue.deliveryFee) {
      this.outlet.deliveryFee = formValue.deliveryFee;
    }
    if (this.outlet.phone !== formValue.phone) {
      this.outlet.phone = formValue.phone;
    }
    if (this.outlet.address !== formValue.address) {
      this.outlet.address = formValue.address;
    }
    if (this.outlet.onlineDelivery !== formValue.address) {
      this.outlet.delivery = formValue.onlineDelivery;
    }
    if (this.outlet.allowLessThanZeroInventory !== formValue.lessThanZeroInvetory) {
      this.outlet.allowLessThanZeroInventory = formValue.lessThanZeroInvetory;
    }
    if (this.outlet.onlineOrder !== formValue.onlineOrder) {
      this.outlet.onlineOrder = formValue.onlineOrder;
    }
    if (this.outlet.privateDeliveryRiders !== formValue.inHouseDispatchersOnly) {
      this.outlet.inHouseDispatchersOnly = formValue.inHouseDispatchersOnly;
    }
    if (this.outlet === oldOutlet) return;
    this.outletService.updateOutlet(this.outlet).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.outletService.saveCurrentOutlet(this.outlet);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: () => {
        this.toastr.error("Something went wrong");
      },
    })
  }
}