import { Component, OnDestroy } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { environment } from '@environments/environment';
import { AuthService, LoadingService } from '@data-access/services';
import { Subject, first, takeUntil } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CreateComplaint, ResponseDTO } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { MenubarModule } from 'primeng/menubar';
import { TextInputFieldComponent } from "../../shared/text-input-field/text-input-field.component";
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink, CommonModule, MenubarModule, OverlayPanelModule, ButtonModule, DialogModule, ReactiveFormsModule,
    TextInputFieldComponent
],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})


export class HeaderComponent implements OnDestroy {
  items: MenuItem[] | undefined;
  isAuthenticated = true;
  profileDropDown = false;
  name: string = environment.me;
  private destroy$ = new Subject<void>();
  complaintVisible: boolean = false;
  disableComplaintSubmission: boolean= false;

//   ngOnInit() {
//     this.items = [
//         {
//             label: 'Home',
//             icon: 'pi pi-home'
//         },
//         {
//             label: 'Features',
//             icon: 'pi pi-star'
//         },
//         {
//             label: 'Contact',
//             icon: 'pi pi-envelope'
//         }
//     ]
// }

  constructor(private authService: AuthService, private router: Router,private toastr: ToastrService, private loadingService:LoadingService) {
    this.authService.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.isAuthenticated = result;
    });
  }

  toggleComplaint(){
    this.complaintVisible = !this.complaintVisible;
  }

  navigate(route:String){
    this.loadingService.isLoading.next(true);
    this.router.navigate([route]);
  }

  createComplaintForm = new FormGroup({
    name: new FormControl(),
    email: new FormControl('',[Validators.required]),
    phone: new FormControl(),
    subject: new FormControl(),
    message: new FormControl()
  })

  createComplaint() {
    this.disableComplaintSubmission = !this.disableComplaintSubmission;
      if(!this.createComplaintForm.valid)return;
      const formValue = this.createComplaintForm.value;
      const data: CreateComplaint = {
        name: formValue.name,
        email: formValue.email as string,
        phone:formValue.phone,
        subject:formValue.subject,
        message:formValue.message
      };
      this.authService.createComplaint(data).pipe(first()).subscribe({
        next: (response: ResponseDTO) => {
          if (response.status) {
            this.toastr.success(response.message);
            this.complaintVisible = false;
          }
          else {
            this.toastr.error(response.message);
          }
        },
        error: (err) => {
          console.log(err);
          this.toastr.error("Something went wrong");
        },
      })
      this.toggleComplaint();
      this.disableComplaintSubmission = !this.disableComplaintSubmission;
    }

  toggleProfileDropdown() {
    this.profileDropDown = !this.profileDropDown;
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['login']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}