<app-auth-layout>

  <div class="flex flex-col items-center w-full">
    <form [formGroup]="loginForm" (submit)="login()">
      <div class="flex flex-col md:w-[100%] items-center">
        <h1 class="mb-2 text-2xl font-bold md:text-3xl md:mb-4">Log in to your Account</h1>
        <span class="text-sm text-center text-gray-500 md:text-base">Sign in to your account and make orders and payments seamlessly</span>
      </div>
      <app-text-input-field [submitted]="loginFormSubmitted" ngDefaultControl label="Email" inputId="registerEmail" placeHolder="Enter Your Email"
        [formControl]="loginForm.controls.email"></app-text-input-field>
      <app-password-field [submitted]="loginFormSubmitted" ngDefaultControl inputId="password" [formControl]="loginForm.controls.password"></app-password-field>
      <div class="flex justify-between w-full mt-4">
        <div class="space-x-3 text-sm md:text-base">
          <!-- <input type="checkbox" name="" id="" />
          <label for="">Remember me</label> -->
        </div>
        <div class="space-x-1 ">
          <a routerLink="/forgot-password" routerLinkActive="active"
            class="hover:underline text-sm md:text:base font-[500] hover:cursor-pointer">Forgot password ?</a>
        </div>
      </div>
      <div class="form_div">
        <button
          class="create_button flex justify-center space-x-4 bg-[#0F172A] text-white hover:bg-[#FAFAFA] hover:text-[#0F172A] hover:shadow-lg duration-300"
          type="submit">
          <span>Login</span>
        </button>
      </div>
    </form>
    <div class="mt-3">Or</div>
    <div class="items-center form_div">
      <div id="googleSignInDiv"></div>
    </div>
    <div class="flex flex-row justify-center w-full mt-6 space-x-1 lg:justify-start">
      <span class="text-gray-500 font-[400]"> Don't have an account ? </span>
      <a routerLink="/register" routerLinkActive="active" class="hover:underline font-[500] hover:cursor-pointer">Create
        a free account</a>
    </div>
    <div class="flex flex-row justify-center w-full mt-6 space-x-1 lg:justify-start">
      <span class="text-gray-500 font-[400]">
        Request Email Verification ?
      </span>
      <a routerLink="/request-verication" routerLinkActive="active"
        class="hover:underline font-[500] hover:cursor-pointer">Request </a>
    </div>
  </div>
</app-auth-layout>