<footer class="relative flex flex-col items-center bg-vendbloclilac md:flex-row justify-between px-2 sm:px-5 md:px-12 py-[12px] w-full bottom-0 m-0 mt-10 lg:mt-5">
  <div>
      <img src="../../../../assets/logo.png" class="h-8" alt="logo">
  </div>
  <!-- VendBloc -->
  <div class="flex items-center text-sm text-white md:text-base opacity-80">
    &copy; All rights Reserved. VendBloc Inc.
  </div>
  <!-- auth buttons and cart -->
  <div class="flex items-center space-x-5">
    
  </div>
</footer>
