<div class="flex items-center justify-between space-x-2 w-full border-b py-1 my-1">
  <!-- Cart Item -->
  <div class="flex flex-row space-x-3 w-[70%]">
    <!-- <img class="product_image" src="../../../assets/meal.png" alt="product image" /> -->
    <!-- Info -->
    <div class="flex flex-col">
      <span class="text-base">{{ cartItem?.product }}</span>
      <div class="flex space-x-5 md:space-x-4 items-center justify-start">
        <span class="opacity-50  text-base">Qty</span>
        <span class="flex items-center">
          <span (click)="updateQuantity(false, cartItem)" class="bg-gray-300 rounded-md py-2.5 px-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 13 2" class="" fill="white">
              <path d="M1 1H12.0194" stroke="#0F172A" stroke-width="1.57419" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <span><input
              class="w-7 text-sm text-vendblocblue align-middle rounded-sm lg:rounded-md p-1 px-0.5 text-center" min="1"
              [value]=cartItem?.orderQuantity disabled name="" id="" /></span>

          <span (click)="updateQuantity(true, cartItem)" class="bg-gray-300 py-1.5 px-1.5 rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 13 14" fill="none">
              <path d="M6.52881 1.49023V12.5096" stroke="#0F172A" stroke-width="1.57419" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M1.01953 7H12.0389" stroke="#0F172A" stroke-width="1.57419" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
        </span>
      </div>
    </div>
  </div>
  <!-- Remove from Cart -->
  <div class="flex items-center justify-between w-[30%] space-x-2">
    <span class="text-vendblocblue opacity-60">{{currency}}{{
       cartItem?.price
      }}</span>
    <span (click)="removeItem(cartItem)" class="flex items-center p-2 justify-center bg-[#E8E8EA] hover:cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M13.5 4.5L4.5 13.5" stroke="#0F172A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.5 4.5L13.5 13.5" stroke="#0F172A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
  </div>
</div>