<header class="flex justify-between px-5 md:px-12 xl:px-12 2xl:px-24 py-[22px]">
  <!-- Logo -->
  <div>
    <a routerLink="/">
      <div>
        <img src="../../../../assets/logo.png" class="h-8" alt="logo">
      </div>
    </a>
  </div>
  @if(isAuthenticated){
  <!-- Links -->
  <div class="flex-col justify-end space-x-2 space-y-3 md:flex-row md:space-x-6 xl:space-x-8 md:items-center">
  </div>
  }
  <!-- auth buttons and cart -->
  <div class="flex items-center space-x-5">
    @if(isAuthenticated){
    <div class="relative inline-block text-left">
      <div>
        <img alt="profile image" (click)="op.toggle($event)"
          src="../../../../assets/user-avatar-profile.png"
          class="object-cover w-6 h-6 rounded-full cursor-pointer">
      </div>
      <p-overlayPanel #op>
        <ng-template pTemplate="content">
          <div class="py-1" role="none">
            <a routerLink="/dashboard" class="block px-2 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
              id="menu-item-0">Dashboard</a>
            <a routerLink="/my-orders" class="block px-2 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
              id="menu-item-0">My Orders</a>
            <a routerLink="/become-dispatcher" class="block px-2 py-2 text-sm text-gray-700" role="menuitem"
              tabindex="-1" id="menu-item-0">Become a dispatcher</a>
            <a (click)="logout()" class="block px-2 py-2 text-sm text-gray-700 cursor-pointer hover:underline"
              role="menuitem" tabindex="-1" id="menu-item-1">Logout</a>
          </div>
        </ng-template>
      </p-overlayPanel>

    </div>
    } @else {
    <!-- <a class="block border-gray-500 auth_button" routerLink="/register">SignUp</a> -->
    <a class="block text-white border-gray-500 auth_button bg-vendblocblue" routerLink="/login">LogIn</a>
    <i (click)="toggleComplaint()" class="pi pi-comment"></i>
    }
  </div>

</header>


<p-dialog header="Reach Us" [(visible)]="complaintVisible" position="top" [modal]="true"
  [breakpoints]="{ '1000px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <form [formGroup]="createComplaintForm" (submit)="createComplaint()">
    <div class="flex flex-col w-full mt-1 space-y-2">
      <app-text-input-field ngDefaultControl label="Email" inputId="email" placeHolder="Enter Your Email"
        [formControl]="createComplaintForm.controls.email"></app-text-input-field>
      <app-text-input-field ngDefaultControl label="Phone" inputId="phone" placeHolder="Enter Your Phone Number"
        [formControl]="createComplaintForm.controls.phone"></app-text-input-field>
      <app-text-input-field ngDefaultControl label="Subject" inputId="subject" placeHolder="Subject of message"
        [formControl]="createComplaintForm.controls.subject"></app-text-input-field>
      <app-text-input-field ngDefaultControl label="Message" inputId="message" placeHolder="Describe your message as much as you can"
        [formControl]="createComplaintForm.controls.message"></app-text-input-field>
      <div class="flex items-end justify-end w-full">
        <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue" [disabled]="disableComplaintSubmission"
          type="submit">Submit</button>
      </div>
    </div>
  </form>
</p-dialog>