export enum ProductType {
    Perishable,
    NonPerishable,
}


export enum OrderStatus {
    Cancelled,
    Pending,
    BusinessApproved,
    AwaitingPickUp,
    DeliveryInProgress,
    Delivered,
    Completed
}

export const PaymentPercentage = {
    SquadPay: 1.2,
    Paystack:1.5,
    VendBloc:1.2
  };