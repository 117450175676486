<app-auth-layout>
    <form [formGroup]="requestVerificationForm" (submit)="requestVerification()">
        <div class="flex flex-col items-center w-full lg:mt-10">
            <div class="flex flex-col md:w-[100%] items-center">
                <h1 class="mb-4 text-3xl font-bold">Request Email Verification</h1>
                <span class="text-center text-gray-500">Request email verification so you can login to your account</span>
            </div>
           
            <div class="form_div">
                <label for="">Enter Your Email Address</label>
                <input class="p-3 border rounded-md" placeholder="Enter Your Email Address" formControlName="email" type="email" required>
            </div>
            <div class="form_div">
                <button class="create_button flex justify-center space-x-4 bg-[#0F172A] text-white hover:bg-[#FAFAFA] hover:text-[#0F172A] hover:shadow-lg duration-300" type="submit">
                    <!-- <div class="w-8 h-8 border-t-4 rounded-full animate-spin"></div> -->
                    <span>Send Email Verification</span></button>
            </div>
            <div class="flex flex-row justify-center w-full mt-6 space-x-1 lg:justify-start">
               <span class="text-gray-500 font-[400]"> Already have an account ? </span>
               <a routerLink="/login" routerLinkActive="active" class="hover:underline font-[500] hover:cursor-pointer">Proceed to Sign In</a>
            </div>
        </div>
    </form>
    
</app-auth-layout>
